import SEO from '@/components/SEO';
import Layout from '@/components/Layout';
import React, { useRef, useState } from 'react';
import { CMS_URL } from '@/config';
import { graphql, useStaticQuery } from 'gatsby';
import DefaultInput from '@/components/Inputs/DefaultInput';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import {
  Container,
  Left,
  Right,
  FormTitle,
  FormContent,
  FooterText,
  FooterForm,
  TextContainer,
  TextTitle,
  TextContent,
  SendedMessage,
  SendedMessageContainer,
  SendedMessageCard,
  ImageContainer,
  ButtonLoading,
} from '@/components/Pages/Contato/styles';
import Select from '@/components/Inputs/Select';
import { StaticImage } from 'gatsby-plugin-image';
import axios from 'axios';
import { removeAllNonNumberCharacters } from '@/utils/removeAllNonNumberCharacters';

const Contato = (): JSX.Element => {
  const query = graphql`
    query {
      directus {
        site_pages(filter: { slug: { _contains: "contato" } }) {
          seo {
            title
            description
            keywords
            image {
              id
            }
          }
        }
      }
    }
  `;

  const { directus } = useStaticQuery(query);
  const SEOData = directus.site_pages[0]?.seo;

  const formRef = useRef<FormHandles>(null);

  const [sended, setSended] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [aceitouTermos, setAceitouTermos] = useState(false);

  const handleSubmit = async (data: any) => {
    setButtonLoading(true);
    const { name, cnpj, whatsapp, email, operacaoVendas } = data;

    if (!aceitouTermos) {
      alert('Você precisa aceitar os termos para enviar o formulário.');
      return;
    }
    try {
      await axios.post(`/api/contato/salvar-lead`, {
        name,
        cnpj: removeAllNonNumberCharacters(cnpj),
        whatsapp: `55${removeAllNonNumberCharacters(whatsapp)}`,
        email,
        operacao_vendas: operacaoVendas,
        aceitou_termos: aceitouTermos,
      });
      setSended(true);
    } catch (error: any) {
      console.log(error);
    } finally {
      setButtonLoading(false);
    }
  };

  return (
    <>
      <SEO
        pageTitle="Contato"
        title={SEOData?.title}
        description={SEOData?.description}
        image={`${CMS_URL}/assets/${SEOData?.image}`}
        keywords={SEOData?.keywords}
      />
      <Layout>
        <>
          {!sended ? (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: ' #f4f8ff',
              }}
            >
              <Container>
                <Left>
                  <FormTitle>Preencha algumas informações rápidas.</FormTitle>
                  <Form ref={formRef} onSubmit={handleSubmit}>
                    <FormContent>
                      <DefaultInput
                        name="name"
                        labelName="Qual o seu nome?"
                        placeholder="Qual o seu nome?"
                        autoComplete="off"
                        tabIndex={1}
                        autoFocus
                        required
                        className="input-form"
                      />
                      <DefaultInput
                        name="email"
                        labelName="E-mail para contato"
                        placeholder="E-mail para contato"
                        autoComplete="off"
                        type="email"
                        tabIndex={2}
                        required
                        className="input-form"
                      />
                      <DefaultInput
                        name="whatsapp"
                        labelName="WhatsApp"
                        placeholder="WhatsApp"
                        autoComplete="off"
                        mask={['(99) 9999-9999', '(99) 99999-9999']}
                        type="tel"
                        tabIndex={3}
                        required
                        className="input-form"
                      />
                      <DefaultInput
                        name="cnpj"
                        labelName="CNPJ da sua empresa"
                        placeholder="CNPJ da sua empresa"
                        autoComplete="off"
                        mask={['999.999.999-99', '99.999.999/9999-99']}
                        type="tel"
                        tabIndex={4}
                        required
                        className="input-form"
                      />
                      <Select
                        name="operacaoVendas"
                        required={true}
                        options={[
                          'Faço até 50 envios por mês',
                          'Faço entre 50 e 500 envios por mês',
                          'Faço mais de 500 envios por mês',
                        ]}
                        firstOption="Selecione sua operação de vendas atual"
                        tabIndex={5}
                      ></Select>
                      <div
                        className="read-terms"
                        onClick={() => setAceitouTermos((prev) => !prev)}
                      >
                        <input
                          defaultChecked={false}
                          name="aceitouTermos"
                          type="checkbox"
                          checked={aceitouTermos}
                          tabIndex={6}
                          required
                          onChange={(e) => {
                            setAceitouTermos(e.target.checked);
                          }}
                        />{' '}
                        Eu li e aceito as{' '}
                        <a
                          href="https://generoso.com.br/politicas-de-privacidade"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Políticas de Privacidade
                        </a>{' '}
                        e do Transporte Generoso. Também aceito que entrem em
                        contato comigo pelos canais fornecidos.
                      </div>
                      <FooterForm>
                        <FooterText>
                          Entraremos em contato em até 10 min!
                        </FooterText>
                        <button
                          type="submit"
                          tabIndex={6}
                          disabled={buttonLoading}
                        >
                          {buttonLoading ? (
                            <>
                              <ButtonLoading />
                            </>
                          ) : (
                            'Enviar'
                          )}
                        </button>
                      </FooterForm>
                    </FormContent>
                  </Form>
                </Left>
                <Right>
                  <TextContainer>
                    <TextTitle>Seja Bem Vindo(a)!</TextTitle>
                    <TextContent>
                      Que bom ver você por aqui! <br /> <br /> Se você está
                      buscando uma transportadora de compromisso que oferece uma
                      entrega rápida e segura, escolha a Generoso! <br /> <br />{' '}
                      Nosso{' '}
                      <span style={{ color: '#407eca' }}>time comercial</span>{' '}
                      está preparado apresentar as melhores soluções para o seu
                      negócio. Seja Generoso!
                    </TextContent>
                  </TextContainer>
                  <ImageContainer>
                    <StaticImage
                      src="../images/paginas/index/mulher-generoso-frente.png"
                      quality={100}
                      alt="Mulher generoso frente"
                      placeholder="blurred"
                    />
                  </ImageContainer>
                </Right>
              </Container>
            </div>
          ) : (
            <SendedMessageContainer>
              <SendedMessageCard>
                <SendedMessage>
                  <h1>Enviado!</h1>
                  <p>
                    Entraremos em contato em até 10 minutos dentro do horário
                    comercial.
                  </p>
                </SendedMessage>
              </SendedMessageCard>
            </SendedMessageContainer>
          )}
        </>
      </Layout>
    </>
  );
};

export default Contato;
