import { colors } from '@/styles/GlobalStyle';
import styled from 'styled-components';

interface IContainer {
  error?: string;
}

export const Container = styled.div<IContainer>`
  select {
    border-radius: 8px;
    border: 1px solid ${(props) => (props.error ? colors.red : colors.gray300)};
    outline: 0;

    width: 100%;

    height: 48px;
    padding-left: 20px;

    margin-top: 5px;

    font-size: 14px;

    background-color: white;
    color: ${colors.gray600};

    -webkit-appearance: none;
    -moz-appearance: none;

    background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
    background-repeat: no-repeat;
    background-position-x: 96%;
    background-position-y: 11px;

    &:active {
      border: 1px solid ${colors.blue400};
    }

    &:disabled {
      cursor: not-allowed;
    }
  }

  select + span {
    margin-left: 5px;
    font-size: 12px;
    height: 10px;

    color: ${colors.red};
  }
`;

export const ErrorMessage = styled.div`
  text-align: start;
  margin-top: 8px;

  color: red;
  font-size: 12px;
  width: 100%;
`;
