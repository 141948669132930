import React, { useRef, useEffect, useState } from 'react';

import { useField } from '@unform/core';

import { ErrorMessage, Container } from './styles';

interface IProps {
  name: string;
  options: Array<string>;
  firstOption: string;
  setUF?: React.Dispatch<React.SetStateAction<string>>;
  required?: boolean;
  tabIndex?: number;
}

const Select: React.FC<IProps> = ({
  name,
  options,
  firstOption,
  setUF,
  required = false,
  tabIndex,
  ...rest
}): JSX.Element => {
  const selectRef = useRef<HTMLSelectElement>(null);
  const [value, setValue] = useState('');
  const { fieldName, registerField, error } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      getValue: () => {
        return selectRef.current?.value;
      },
    });
  }, [fieldName, registerField]);

  useEffect(() => {
    if (setUF) {
      setUF(value);
    }
  }, [value, setUF]);

  return (
    <Container error={error}>
      <select
        ref={selectRef}
        value={value}
        onChange={(e) => setValue(e.target.value)}
        {...rest}
        required={required}
        tabIndex={tabIndex}
      >
        <option value="" hidden>
          {firstOption}
        </option>
        {options.map((option) => (
          <option value={option} key={option}>
            {option}
          </option>
        ))}
      </select>
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </Container>
  );
};

export default Select;
