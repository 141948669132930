import styled, { keyframes } from 'styled-components';
import { colors } from '@/styles/GlobalStyle';

export const Container = styled.div`
  max-width: 1380px;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 2fr;

  @media (max-width: 1140px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 900px) {
    grid-template-columns: 1fr;
  }
`;

export const Left = styled.div`
  min-width: 24vw;
  height: fit-content;
  display: grid;
  gap: 16px;
  background-color: #fff;
  border-radius: 16px;
  flex-direction: column;
  padding: 24px;
  margin: 24px;

  @media (max-width: 1140px) {
    width: 50vw;
  }

  @media (max-width: 900px) {
    width: calc(100% - 48px);
  }
`;

export const Right = styled.div`
  display: flex;

  @media (max-width: 900px) {
    grid-row: 1;
  }
`;

export const FormTitle = styled.h4`
  font-size: 24px;
  font-weight: 700;
  color: #000;
`;

export const FormContent = styled.div`
  width: 100%;
  gap: 16px;
  display: grid;

  .read-terms {
    font-size: 16px;
    margin-top: 16px;
    line-height: 20px;
    cursor: pointer;
  }

  > div {
    width: 100%;
  }

  a {
    text-decoration: underline;
  }

  .input-form {
    margin: 0;
    height: 56px !important;

    div {
      height: 56px !important;
    }

    input {
      height: 56px !important;
    }
  }

  select {
    border-radius: 8px;
    border: 1px solid ${colors.gray300};

    width: 100%;

    height: 56px;

    font-size: 16px;

    background-color: transparent;
    color: ${colors.gray600};

    box-shadow: 0px 4px 20px 0px transparent;

    background-repeat: no-repeat;
    background-position-x: 98%;
    background-position-y: 11px;

    @media (max-width: 700px) {
      font-size: 16px;
    }

    padding: 0 30px 0 10px !important;
    -webkit-padding-end: 30px !important;
    -webkit-padding-start: 10px !important;

    &:focus {
      border: 1px solid ${colors.blue400};
      box-shadow: 0px 4px 20px 0px ${colors.blue100};
    }
  }
`;

export const FooterForm = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  margin-top: 24px;

  @media (max-width: 700px) {
    justify-content: flex-end;
  }

  button {
    display: flex;
    justify-content: center;
    align-items: center;

    height: 40px;
    width: 128px;

    border-radius: 8.4px;
    border: none;

    background-color: ${colors.blue400};

    color: white;
    font-size: 16px;
    font-weight: 500;

    &:hover {
      color: white;
      background-color: #052373;
      transition: 0.2s;
    }

    &:focus {
      border: 1px solid #407eca !important;
    }
  }
`;

export const FooterText = styled.p`
  font-size: 16px;
  color: #407eca;
  max-width: 200px;

  @media (max-width: 1300px) {
    font-size: 1rem;
  }

  @media (max-width: 700px) {
    display: none;
  }
`;

export const TextContainer = styled.div`
  width: 24vw;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin: 0px 24px 0px 24px;

  @media (max-width: 1140px) {
    width: 100%;
  }

  @media (max-width: 700px) {
    width: 100%;
  }
`;

export const TextTitle = styled.h1`
  font-size: 56px;
  font-weight: 700;
  color: ${colors.blue400};
  margin-top: 16px;

  @media (max-width: 1300px) {
    font-size: 3rem;
  }

  @media (max-width: 700px) {
    font-size: 2rem;
  }
`;

export const TextContent = styled.p`
  font-size: 16px;
  max-width: 100%;
  color: #000;
  margin: 0px 0px 16px 0px;
`;

export const SendedMessageContainer = styled.div`
  width: 100%;
  height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f4f8ff;
`;

export const SendedMessageCard = styled.div`
  width: fit-content;
  height: fit-content;
  padding: 36px 36px 36px 36px;
  margin: 0px 16px 0px 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: #fff;
  border-radius: 24px;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 150, 0.1);

  @media (max-width: 700px) {
    padding: 16px 16px 16px 16px;
  }
`;

export const SendedMessage = styled.div`
  margin: 0;
  font-size: 24px;
  color: ${colors.blue300};

  h1 {
    line-height: 72px;
    margin: 0;
  }

  @media (max-width: 700px) {
    font-size: 16px;

    h1 {
      line-height: 48px;
    }
  }
`;

export const ImageContainer = styled.div`
  display: flex;
  align-items: end;
  justify-content: flex-end;
  max-height: 720px;
  width: 448px !important;
  margin-top: 16px;

  .gatsby-image-wrapper {
    height: 100% !important;

    img {
      object-fit: contain !important;
    }
  }

  @media (max-width: 1140px) {
    display: none !important;
  }
`;

const rotate = keyframes`
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
`;

export const ButtonLoading = styled.div`
  border: 2px solid ${colors.white};
  border-top: 4px solid ${colors.blue400};
  border-radius: 50%;
  width: 24px;
  height: 24px;
  animation: ${rotate} 0.5s linear infinite;
`;
